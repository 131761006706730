@use "sass:map";

$break_points: (
  xs: 0px,
  sm: 576px,
  md: 768px,
  lg: 992px,
  lgl: 1080px,
  xl: 1200px,
  xxl: 1440px,
);

@mixin responsive-down($breakpoint) {
  @media (max-width: map.get($break_points, $breakpoint)) {
    @content;
  }

  // @if $breakpoint == sm {
  //   @media (max-width: 576px) {
  //     @content;
  //   }
  // }
  // @if $breakpoint == md {
  //   @media (max-width: 768px) {
  //     @content;
  //   }
  // }
  // @if $breakpoint == lg {
  //   @media (max-width: 992px) {
  //     @content;
  //   }
  // }
  // @if $breakpoint == xl {
  //   @media (max-width: 1200px) {
  //     @content;
  //   }
  // }
  // @if $breakpoint == xxl {
  //     @media (max-width: 1440px) {
  //       @content;
  //     }
  // }
}

@mixin responsive-up($breakpoint) {
  @media (min-width: map.get($break_points, $breakpoint)) {
    @content;
  }

  // @if $breakpoint == xs {
  //     @media (min-width: 0px) {
  //       @content;
  //     }
  //   }
  // @if $breakpoint == sm {
  //   @media (min-width: 576px) {
  //     @content;
  //   }
  // }
  // @if $breakpoint == md {
  //   @media (min-width: 768px) {
  //     @content;
  //   }
  // }
  // @if $breakpoint == lg {
  //   @media (min-width: 992px) {
  //     @content;
  //   }
  // }
  // @if $breakpoint == xl {
  //   @media (min-width: 1200px) {
  //     @content;
  //   }
  // }
  // @if $breakpoint == xxl {
  //     @media (min-width: 1440px) {
  //       @content;
  //     }
  // }
}

@mixin csw($screen, $min: false) {
  @if $min {
    @media (min-width: $screen+"px") {
      @content;
    }
  } @else {
    @media (max-width: $screen+"px") {
      @content;
    }
  }
}
