@import "styles/_colors.scss"; @import "styles/_variables.scss"; @import "styles/_cmixins.scss";
.nft_model_wrapper {
  width: 100vw;
  height: 100vh;

  background: transparentize($color: #000000, $amount: 0.6);

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  z-index: 1200;
  position: fixed;

  top: 0;

  &.show {
    display: flex;
  }

  &.hide {
    display: none;
  }

  .nft_model {
    display: flex;
    flex-direction: column;
    opacity: 1;

    width: 412px;
    z-index: 1;
    // height: 316px;

    max-width: 100vw;
    max-height: 100vh;

    background: #ffffff;
    box-shadow: 0px 8px 36px rgba(0, 0, 0, 0.16);
    border-radius: 8px;
    padding: 24px;

    @include csw(1024) {
      width: 370px;
    }

    @include responsive-down(lg) {
      width: 350px;
    }

    .header {
      display: flex;
      flex: 1;
      flex-direction: row;
      justify-content: space-between;
      opacity: 1;
      margin: 8px 0;

      .title {
        display: flex;
        flex: 0.9;

        font-family: $primary_font;
        font-style: normal;
        font-weight: 500;
        font-size: 32px;
        line-height: 46px;
        display: flex;
        align-items: center;
        color: $primary_font_color;

        margin: 0;
      }

      .close {
        display: flex;
        flex: 0.1;
        justify-content: flex-end;
        // align-items: center;
        font-size: 24px;
        margin: 5px 0 0 0;

        svg {
          fill: #cccccc;

          &:hover {
            fill: #000000;
            cursor: pointer;
          }
        }
      }
    }

    .body {
      overflow: auto;
      &::-webkit-scrollbar {
        width: 5px;
      }
      &::-webkit-scrollbar-track {
        background: #222227;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #bdbdbd;
        outline: 0px solid #222227;
      }
    }

    @include responsive-down(sm) {
      width: 100vw;
      position: absolute;
      bottom: 40px;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
}

.headerTitle {
  display: flex;
  justify-content: center;
  align-items: center;
}